import { tns } from "tiny-slider/src/tiny-slider";

document.addEventListener('DOMContentLoaded', function (evt) {
    /*
     * Sliders
     */
    let slides = document.querySelector('.slides');
    if (slides) {
        tns({
            container: '.slides',
            autoplay: true,
            mode: 'gallery',
            speed: 2500,
            controls: false,
            autoplayButtonOutput: false,
            nav: false,
        });
    }

    /*
     * Inhibit scrolling body when main nav is open.
     */
    let stopEvent = function (evt) {
        evt.preventDefault();
    };
    let scrollEvents = ['wheel', 'touchmove'];
    let navToggler = document.getElementById('menu-toggler');
    if (navToggler) {
        navToggler.addEventListener('input', function (evt) {
            if (this.checked) {
                scrollEvents.forEach(evt => window.addEventListener(evt, stopEvent));
            } else {
                scrollEvents.forEach(evt => window.removeEventListener(evt, stopEvent));
            }
        });
        navToggler.dispatchEvent(new Event('input'));
    }
});
